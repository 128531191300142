<template>
  <div>
    <v-overlay :value="overlay" z-index="999999">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>

    <v-data-table
      :headers="headers"
      :items="dataTable"
      class="elevation-1"
      :loading="loadingTable"
      :search="search"
      :items-per-page="itemsPerPage"
      item-key="tenantId"
      loading-text="Buscando horarios..."
      dense
    >
      <template v-slot:top>
        <v-col cols="12">
          <v-row align="center" justify="center">
            <v-col cols="12" md="3">
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="pt-5"
                    v-model="dateRangeText"
                    label="Intervalo de fechas"
                    persistent-hint
                    readonly
                    append-icon="mdi-calendar-multiple"
                    v-bind="attrs"
                    v-on="on"
                    clearable
                    click:clear="dates=''"
                  />
                </template>
                <v-date-picker
                  range
                  v-model="dates"
                  no-title
                  @change="searchEventos"
                />
              </v-menu>
            </v-col>
            <v-col cols="12" md="2">
              <v-autocomplete
                label="Tienda"
                v-model="productStoreId"
                :items="productStores"
                dense
                item-value="product_store_id"
                item-text="store_name"
                hide-details="auto"
                @change="getEventosCalendario('ListadoEventos')"
              />
            </v-col>
            <v-col cols="12" md="2">
              <v-autocomplete
                label="Empleado"
                v-model="busEmpleadoId"
                :items="empleados"
                dense
                item-value="party_id"
                item-text="nombre"
                hide-details="auto"
                clearable
                @change="getEventosCalendario('ListadoEventos')"
              />
            </v-col>
            <v-col cols="12" md="2">
              <v-autocomplete
                label="Tipo de evento"
                v-model="eventType"
                :items="servicioCrm"
                dense
                item-value="servicio_crm_id"
                item-text="nombre"
                hide-details="auto"
                clearable
                @change="getEventosCalendario('ListadoEventos')"
              />
            </v-col>
            <v-col cols="12" md="2">
              <v-autocomplete
                label="Estado"
                v-model="state"
                :items="states"
                dense
                item-value="status_id"
                item-text="description"
                hide-details="auto"
                clearable
                @change="getEventosCalendario('ListadoEventos')"
              />
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field v-model="search" label="Buscar" />
            </v-col>
            <v-col cols="12" md="1" class="text-center">
              <v-btn
                color="success"
                dark
                @click="dialogCalendarioEvento = true"
                small
                fab
              >
                <v-icon>mdi-calendar</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </template>
      <template v-slot:no-results="{ item }">
        No se encontraron eventos
      </template>
      <template v-slot:no-data> Sin registros </template>
      <template v-slot:item.work_effort_id="{ item }">
        <v-btn
          small
          text
          title="Ver detalle evento"
          :to="`/evento_form/${item.work_effort_id}`"
          target="_blank"
          ><v-icon small color="green" left>mdi-calendar-multiple-check</v-icon>
          {{ item.work_effort_id }}
        </v-btn>
      </template>
      <template v-slot:item.sales_opportunity_id="{ item }">
        <v-btn
          v-if="item.sales_opportunity_id != null"
          text
          small
          target="_blank"
          title="abrir oportunidad"
          :to="`/oportunidad_form/${item.sales_opportunity_id}`"
          ><v-icon small left color="green">mdi-handshake</v-icon>
          {{ item.sales_opportunity_id }}
        </v-btn>
      </template>
      <template v-slot:item.cliente="{ item }">
        <v-btn
          text
          small
          target="_blank"
          title="ver contacto"
          :to="`/contacto_form/${item.party_id_cliente}`"
          ><v-icon small left color="green">mdi-contacts-outline</v-icon>
          {{ item.cliente }}
        </v-btn>
      </template>
      <template v-slot:item.date="{ item }">
        {{ item.estimated_start_date.split(" ")[0] }}
      </template>
      <template v-slot:item.start="{ item }">
        {{
          moment2(item.estimated_start_date.split(" ")[1], "HH:mm:ss").format(
            "HH:mm"
          )
        }}
      </template>
      <template v-slot:item.end="{ item }">
        {{
          moment2(
            item.estimated_completion_date.split(" ")[1],
            "HH:mm:ss"
          ).format("HH:mm")
        }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="success"
              class="mx-2"
              v-bind="attrs"
              v-on="on"
              @click="verEstadoEvento(item)"
            >
              mdi-comment-processing-outline
            </v-icon>
          </template>
          <span>Comentario</span>
        </v-tooltip>
        <v-tooltip
          bottom
          v-if="
            ![
              'CAL_TAR_CANCELADO',
              'CAL_TAR_COMPLETADO',
              'CAL_TAR_REAGENDADO',
            ].includes(item.current_status_id)
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="success"
              class="mx-2"
              v-bind="attrs"
              v-on="on"
              @click="seleccionaEventoEstado($event, item)"
            >
              mdi-toggle-switch
            </v-icon>
          </template>
          <span>Cambiar estado</span>
        </v-tooltip>
        <v-tooltip
          bottom
          v-if="
            [
              'CAL_TAR_PLANIFICADO',
              'CAL_TAR_NO_REALIZADO',
              'CAL_TAR_NOASISTE',
            ].includes(item.current_status_id)
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="warning"
              class="mx-2"
              v-bind="attrs"
              v-on="on"
              @click="setDataReagendarEvento(item)"
            >
              mdi-repeat
            </v-icon>
          </template>
          <span>Reagendar</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog
      fullscreen
      v-model="dialogCalendarioEvento"
      :persistent="true"
      no-click-animation
      :retain-focus="false"
    >
      <v-toolbar dark color="primary" dense>
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-col cols="12" md="8" sm="12" class="caption text-md-h6">
          <v-icon>mdi-calendar-clock</v-icon> CALENDARIO DE EVENTOS
        </v-col>
      </v-toolbar>
      <v-card>
        <v-row class="fill-height">
          <v-col md="3" class="text-center">
            <v-menu bottom right style="width: 90%">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="my-3"
                  outlined
                  color="grey darken-2"
                  v-bind="attrs"
                  v-on="on"
                  style="width: 90%"
                >
                  {{
                    typeof productStoreSelected != "undefined"
                      ? productStoreSelected.store_name
                      : "Tienda"
                  }}
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item-title style="cursor: pointer"
                  >Tienda</v-list-item-title
                >
                <v-list-item
                  v-for="(ps, x) in productStores"
                  :key="x"
                  style="cursor: pointer"
                  @click="setProductStoreIdEvento(ps)"
                >
                  <v-list-item-title style="cursor: pointer">{{
                    ps.store_name
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-date-picker
              v-model="fechaCalendario"
              @change="
                (day) => {
                  focus = day;
                }
              "
              locale="es"
            />
            <div><b>Servicio seleccionado:</b></div>
            <div class="mb-3">
              <v-menu bottom right style="width: 90%">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                    style="width: 90%; font-size: 13px"
                  >
                    <span></span>
                    {{
                      typeof servicioSelected != "undefined"
                        ? `${servicioSelected.nombre} Duración: ${duracion}(min)`
                        : "Todos los servicios"
                    }}
                    <v-icon right> mdi-menu-down </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item-title
                    style="cursor: pointer"
                    @click="setServicioCrmIdEvento({ servicio_crm_id: null })"
                  >
                    Todos los servicios
                  </v-list-item-title>
                  <v-list-item
                    v-for="(serv, x) in servicios"
                    :key="x"
                    @click="setServicioCrmIdEvento(serv)"
                  >
                    <v-list-item-title style="cursor: pointer"
                      >{{ serv.nombre }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <div class="mb-3" style="width: 90%; margin: 0 auto">
              <b>Sub servicios seleccionados:</b>
              <div v-if="typeof servicioSelected != 'undefined'">
                <v-autocomplete
                  v-model="subServiciosSeleccionados"
                  :items="servicioSelected.sub_servicios"
                  dense
                  outlined
                  :single-line="true"
                  item-value="sub_servicio_crm_id"
                  :item-text="
                    (item) => `${item.internal_name} ${item.duracion}min`
                  "
                  multiple
                  hide-details
                />
              </div>
              <div v-else>Seleccione el servicio</div>
            </div>
            <div class="my-2">
              <div><b>Horario seleccionado:</b></div>
              <div>{{ horaOpcionAgendaCalendario }}</div>
            </div>
            <div class="my-2" style="width: 90%; margin: 0 auto">
              <v-btn
                class="white--text"
                color="grey darken-1"
                block
                @click="getOpcionesAgendaCalendario"
              >
                <v-icon>mdi-calendar-clock</v-icon> VER DISPONIBILIDADES
              </v-btn>
            </div>
          </v-col>
          <v-col md="9">
            <v-sheet height="64">
              <v-toolbar flat>
                <v-btn
                  outlined
                  class="mr-4"
                  color="grey darken-2"
                  @click="setToday"
                >
                  Hoy
                </v-btn>
                <v-toolbar-title v-if="$refs.calendar">
                  {{ $refs.calendar.title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-menu bottom v-if="1 == 2" right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      color="grey darken-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <span></span>
                      {{
                        typeof empleadoSelected != "undefined"
                          ? empleadoSelected.nombre
                          : "Empleados"
                      }}
                      <v-icon right> mdi-menu-down </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item-title
                      style="cursor: pointer"
                      @click="setEmpleadoIdEvento({ party_id: null })"
                    >
                      Empleados
                    </v-list-item-title>
                    <v-list-item
                      v-for="(emp, x) in empleados"
                      :key="x"
                      @click="setEmpleadoIdEvento(emp)"
                    >
                      <v-list-item-title style="cursor: pointer">{{
                        emp.nombre.toUpperCase()
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-spacer></v-spacer>
                <v-combobox
                  v-if="1 == 2"
                  ref="combobox"
                  style="width: 150px"
                  class="mt-1"
                  v-model="partyIdCliente"
                  :search-input.sync="searchClienteList"
                  hide-details
                  :items="clientes"
                  label="Cliente"
                  item-text="nombre"
                  item-value="party_id"
                  outlined
                  dense
                  @keydown.enter="getClientes"
                  @change="getEventosCalendario('CalendarioEventos')"
                  clearable
                />
                <v-spacer></v-spacer>
                <v-menu bottom right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      color="grey darken-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <span>{{ typeToLabel[type] }}</span>
                      <v-icon right> mdi-menu-down </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="type = 'day'">
                      <v-list-item-title>Día</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'week'">
                      <v-list-item-title>Semana</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'month'">
                      <v-list-item-title>Mes</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = '4day'">
                      <v-list-item-title>4 días</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-toolbar>
            </v-sheet>
            <v-sheet height="750">
              <v-calendar
                ref="calendar"
                color="primary"
                event-more-text="Mas de 2 eventos"
                interval-minutes="15"
                locale="es"
                v-model="focus"
                :event-more="true"
                :event-ripple="false"
                :event-height="eventHeight"
                :events="events"
                :event-color="getEventColor"
                :type="type"
                :first-time="horaInicioCalendario"
                :end="horaFinCalendario"
                :interval-count="intervalCountCalendar"
                @click:more="viewDay"
                @click:date="viewDay"
                @click:time="agendarCalendario"
                @click:day="enterDay"
                @change="getEventosCalendario('CalendarioEventos')"
              >
                <template v-slot:day-label-header="{ day }">
                  <div class="text-center label-day-view">
                    {{ day }}
                  </div>
                </template>
                <template v-slot:event="{ event }">
                  <div
                    style="white-space: initial; padding: 5px"
                    @click="
                      (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }
                    "
                  >
                    <v-icon
                      v-if="
                        ['week', '4day', 'day'].includes(type) &&
                          !event.showActions
                      "
                      style="
                          background-color: white;
                          padding: 4px;
                          border-radius: 30px;
                        "
                      class="mb-2 text--white"
                      @click="showEvent(event, $event)"
                    >
                      {{
                        openDivEvent ? "mdi-arrow-collapse" : "mdi-arrow-expand"
                      }}
                    </v-icon>
                    <div>
                      <b>- CLIENTE:</b> {{ event.cliente }} [{{
                        event.party_id_cliente
                      }}] -
                      {{ event.start.split(" ")[0] }}
                      {{ event.start.split(" ")[1] }}
                      {{ event.end.split(" ")[1] }}
                    </div>
                    <div>
                      <b>- SERVICIO:</b> {{ event.servicio }} -
                      {{ event.empleado }}
                    </div>
                    <div>
                      <b>- DURACIÓN:</b>
                      {{
                        event.sub_servicios.reduce(
                          (a, b) => a + parseFloat(b.duracion),
                          0
                        ) +
                          (isNaN(parseFloat(event.tiempo_antes))
                            ? 0
                            : parseFloat(event.tiempo_antes)) +
                          (isNaN(parseFloat(event.tiempo_despues))
                            ? 0
                            : parseFloat(event.tiempo_despues))
                      }}
                      min
                    </div>
                    <div><b>- EVENTO:</b> {{ event.workEffortId }}</div>
                    <div><b>- ESTADO:</b> {{ event.state }}</div>
                    <div>
                      <b>- COMENTARIO:</b>
                      <span v-html="event.otro_motivo"></span>
                    </div>
                    <div class="mt-3" v-if="openDivEvent && !event.showActions">
                      <div class="col text-center">
                        <div class="row">
                          <div class="col-md-6">
                            <v-btn
                              color="success"
                              small
                              @click="seleccionaEventoEstado($event, event)"
                            >
                              <v-icon>mdi-repeat</v-icon> Cambiar Estado
                            </v-btn>
                          </div>
                          <div class="col-md-6">
                            <v-btn
                              color="warning"
                              small
                              @click="setDataReagendarEvento(event)"
                            >
                              <v-icon>mdi-calendar-multiple</v-icon> Reagendar
                            </v-btn>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-slot:interval="{ date, time }">
                  <div
                    :class="
                      `text-center ${
                        !(typeof diasHorarios[date] != 'undefined'
                          ? diasHorarios[date].includes(time + ':00')
                          : '')
                          ? 'tiempo-no-disponible'
                          : ''
                      }`
                    "
                  ></div>
                </template>
              </v-calendar>
            </v-sheet>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog
      width="750px"
      v-model="dialogOpcionesAgendaCalendario"
      :persistent="true"
      no-click-animation
      :retain-focus="false"
    >
      <v-toolbar style="position: static" class="blue-grey lighten-5">
        <v-app-bar-nav-icon>
          <template v-slot:default>
            <v-icon color="primary">mdi-48px mdi-clock</v-icon>
          </template>
        </v-app-bar-nav-icon>
        <v-card-title class="justify-center py-0">
          HORARIOS DISPONIBLES (Tiempo necesario {{ duracion }}min)
        </v-card-title>
      </v-toolbar>
      <v-card>
        <p class="px-5 pt-3">
          Agendar el <b>{{ focus }}</b> con:
          <b>{{ nombreOpcionAgendaCalendario }}</b> a las:
          <b> {{ horaOpcionAgendaCalendario }}</b>
        </p>
        <v-row>
          <v-col cols="6">
            <v-combobox
              ref="combobox"
              style="width: 350px"
              class="mt-1 ml-5 mb-4"
              v-model="partyIdCliente"
              :search-input.sync="searchClienteList"
              hide-details
              :items="clientes"
              label="Cliente"
              item-text="nombre"
              item-value="sales_opportunity_id"
              outlined
              dense
              @keydown.enter="getClientes"
              @change="setClienteOp"
              clearable
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              class="mt-1 ml-5 mb-4"
              label="oportunidad"
              style="width: 140px"
              readonly
              v-model="sales_opp_id"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-list two-line>
          <v-list-item-group active-class="pink--text">
            <template v-for="(opcion, x) in opcionesAgendaCalendario">
              <v-list-item :key="x.party_id">
                <template v-slot:default="{ active }">
                  <v-list-item-content>
                    <v-list-item-title>
                      <b>{{ opcion.nombre }}</b>
                    </v-list-item-title>
                    <v-list-item-subtitle class="text--primary"
                      >Disponibilidades:</v-list-item-subtitle
                    >
                    <v-row justify="space-around">
                      <v-menu
                        v-for="(disp, y) in opcion.disponibilidades[focus].filter((e) => returnDiffMinutos(e) >= duracion)"
                        :key="y"
                        bottom
                        :close-on-content-click="false"
                        origin="center center"
                        transition="scale-transition"
                        class="round p-2"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            small
                            color="primary"
                            class="my-3"
                            v-bind="attrs"
                            v-on="on"
                            @click="setPersonaYhora(opcion)"
                          >
                            <div>{{ disp.desde }} - {{ disp.hasta }}</div>
                          </v-btn>
                        </template>
                        <v-list>
                          <VueTimepicker
                            hour-label="Hora"
                            minute-label="Minuto"
                            drop-direction="bottom"
                            input-width="100%"
                            :second-interval="15"
                            v-model="horaOpcionAgendaCalendario"
                            auto-scroll
                            hide-disabled-items
                            close-on-complete
                            style="height: 190px"
                            :hour-range="[
                              [
                                disp.desde.split(':')[0],
                                disp.hasta.split(':')[0],
                              ],
                            ]"
                            @change="rangoMinutos(disp)"
                            :minute-range="[rangoMinutos(disp)]"
                          />
                        </v-list>
                      </v-menu>
                    </v-row>
                  </v-list-item-content>
                </template>
              </v-list-item>
              <v-divider :key="x"></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
        <v-card-actions>
          <v-col class="text-center">
            <v-btn
              @click="
                agendarCalendario(
                  {
                    date: focus,
                    time: horaOpcionAgendaCalendario,
                    hour: horaOpcionAgendaCalendario,
                  },
                  'disponibilidades'
                )
              "
              class="text-center mr-2"
              color="success"
            >
              <v-icon>mdi-floppy</v-icon> AGENDAR
            </v-btn>
            <v-btn
              @click="closeDialogOpcionesAgendaCalendario"
              class="text-center"
            >
              <v-icon>mdi-cancel</v-icon> CERRAR
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      width="750px"
      v-model="dialogEstadoEvento"
      :persistent="true"
      no-click-animation
    >
      <v-toolbar style="position: static" class="blue-grey lighten-5">
        <v-app-bar-nav-icon>
          <template v-slot:default>
            <v-icon color="primary">mdi-48px mdi-repeat</v-icon>
          </template>
        </v-app-bar-nav-icon>
        <v-card-title class="justify-center py-0">
          DETALLES DEL EVENTO
        </v-card-title>
      </v-toolbar>
      <v-card>
        <v-form ref="form_estatus_evento">
          <v-card-text class="pt-5">
            <p><b>Servicio:</b> {{ eventoEstado.servicio }}</p>
            <p><b>Estado actual:</b> {{ eventoEstado.state }}</p>
            <p><b>Cliente:</b> {{ eventoEstado.cliente }}</p>
            <p><b>Quien atiende:</b> {{ eventoEstado.empleado }}</p>
            <p>
              <b>Hora de atención:</b>
              {{
                eventoEstado.estimated_start_date.split(":")[0] +
                  ":" +
                  eventoEstado.estimated_start_date.split(":")[1]
              }}
              -
              {{
                eventoEstado.estimated_completion_date.split(":")[0] +
                  ":" +
                  eventoEstado.estimated_completion_date.split(":")[1]
              }}
            </p>
          </v-card-text>

          <v-card-text>
            <v-autocomplete
              label="Actualizar estado"
              v-model="newStatusId"
              :items="
                states.filter((e) => {
                  //return enventoNoRealizados.includes(e.status_id);
                  return getListaEstadosValidos().includes(e.status_id) && (e.status_id == 'CAL_TAR_COMPLETADO' ? (  moment2(eventoEstado.estimated_start_date).format('YYYY-MM-DD') <= moment2().format('YYYY-MM-DD')) : true );
                })
              "
              dense
              :rules="required"
              outlined
              item-value="status_id"
              item-text="description"
            />
            <v-autocomplete
              :rules="
                getListaMotivosEstado(newStatusId).length == 0 ? [] : required
              "
              label="Motivos de cambio de estado"
              v-model="nuevoMotivoId"
              :items="getListaMotivosEstado(newStatusId)"
              dense
              outlined
              item-value="wes_motivo_id"
              item-text="motivo_nombre"
            />
            <v-textarea
              v-model="motivo"
              dense
              :rules="
                ['CAL_TAR_COMPLETADO', 'CAL_TAR_CONFIRMADO'].includes(
                  newStatusId
                ) && newStatusId != null
                  ? []
                  : required
              "
              outlined
              label="Comentario del cambio de estado"
            />
          </v-card-text>
          <v-card-actions>
            <v-col class="text-center">
              <v-btn
                @click="cambiarEstadoEvento"
                class="text-center mr-2"
                color="success"
              >
                <v-icon>mdi-floppy</v-icon> ACTUALIZAR
              </v-btn>
              <v-divider vertical class="mx-4"></v-divider>
              <v-btn @click="dialogEstadoEvento = false" class="text-center">
                <v-icon>mdi-cancel</v-icon> CERRAR
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog width="950px" v-model="dialogReagendaEvento" :persistent="true">
      <v-toolbar style="position: static" class="blue-grey lighten-5">
        <v-app-bar-nav-icon>
          <template v-slot:default>
            <v-icon color="primary">mdi-48px mdi-repeat</v-icon>
          </template>
        </v-app-bar-nav-icon>
        <v-card-title class="justify-center py-0">
          DETALLES DEL REAGENDAMIENTO
        </v-card-title>
      </v-toolbar>
      <v-card>
        <v-card-text>
          <v-form ref="form_reagenda_evento">
            <v-row>
              <v-col md="4">
                <v-date-picker
                  v-model="eventoReagendado.fecha"
                  @change="searchDisponibilidadReagendado"
                  locale="es"
                />
                <div class="text-center"><b>Servicio seleccionado:</b></div>
                <div class="mb-3">
                  <v-autocomplete
                    v-model="eventoReagendado.servicio_crm_id"
                    :items="servicios"
                    dense
                    outlined
                    :single-line="true"
                    item-value="servicio_crm_id"
                    :rules="required"
                    hide-details="auto"
                  >
                    <template v-slot:item="{ item }">
                      {{ item.nombre }}
                    </template>
                    <template v-slot:selection="{ attr, on, item, selected }">
                      <span style="font-size: 12px">
                        {{ item.nombre }}
                        <b>
                          Duración: {{ `${duracionEventoReagendado}min ` }}</b
                        >
                      </span>
                    </template>
                  </v-autocomplete>
                </div>
                <div
                  class="mb-3 text-center"
                  style="width: 100%; margin: 0 auto"
                >
                  <b>Sub servicios seleccionados:</b>
                  <div>
                    <v-autocomplete
                      v-model="eventoReagendado.sub_servicio_crm_id"
                      :items="
                        typeof servicioReagendadoSelected != 'undefined'
                          ? servicioReagendadoSelected.sub_servicios
                          : []
                      "
                      dense
                      outlined
                      :single-line="true"
                      item-value="sub_servicio_crm_id"
                      :item-text="
                        (item) => `${item.internal_name} ${item.duracion}min`
                      "
                      multiple
                      :rules="required"
                      hide-details="auto"
                    />
                  </div>
                </div>
                <div class="my-2 text-center">
                  <div><b>Horario seleccionado:</b></div>
                  <div>{{ eventoReagendado.hora }}</div>
                </div>
              </v-col>
              <v-col md="8">
                <v-list class="pt-0">
                  <v-toolbar class="blue-grey lighten-5" dense>
                    <v-card-title class="justify-center py-0">
                      HORARIOS DISPONIBLES (Tiempo necesario
                      {{ duracionEventoReagendado }}min)
                    </v-card-title>
                  </v-toolbar>
                  <p class="mt-5">
                    Agendar el <b>{{ eventoReagendado.fecha }}</b> con:
                    <b>{{ nombreOpcionAgendaCalendario }}</b> a las:
                    <b>{{ horaOpcionReagendadoCalendario }}</b>
                  </p>
                  <v-divider></v-divider>
                  <v-list-item-group active-class="pink--text">
                    <template v-if="opcionesReagendadoCaledario != undefined">
                      <template
                        v-for="(opcion, x) in opcionesReagendadoCaledario"
                      >
                        <v-list-item :key="x.party_id">
                          <template v-slot:default="{ active }">
                            <v-list-item-content>
                              <v-list-item-title>
                                <b>{{ opcion.nombre }}</b>
                              </v-list-item-title>
                              <v-list-item-subtitle class="text--primary"
                                >Disponibilidades:</v-list-item-subtitle
                              >
                              <v-row justify="space-around">
                                <v-menu
                                  v-for="(disp, y) in opcion.disponibilidades[
                                    eventoReagendado.fecha
                                  ].filter(
                                    (e) =>
                                      returnDiffMinutos(e) >=
                                      duracionEventoReagendado
                                  )"
                                  :key="y"
                                  bottom
                                  :close-on-content-click="false"
                                  origin="center center"
                                  transition="scale-transition"
                                  class="round p-2"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      small
                                      color="primary"
                                      class="my-3"
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="setPersonaYhora(opcion)"
                                    >
                                      <div>
                                        {{ disp.desde }} - {{ disp.hasta }}
                                      </div>
                                    </v-btn>
                                  </template>
                                  <v-list>
                                    <VueTimepicker
                                      hour-label="Hora"
                                      minute-label="Minuto"
                                      drop-direction="bottom"
                                      input-width="100%"
                                      :second-interval="15"
                                      v-model="horaOpcionReagendadoCalendario"
                                      auto-scroll
                                      hide-disabled-items
                                      close-on-complete
                                      style="height: 190px"
                                      :hour-range="[
                                        [
                                          disp.desde.split(':')[0],
                                          disp.hasta.split(':')[0],
                                        ],
                                      ]"
                                      @change="rangoMinutos(disp, true)"
                                      :minute-range="[rangoMinutos(disp, true)]"
                                    />
                                  </v-list>
                                </v-menu>
                              </v-row>
                            </v-list-item-content>
                          </template>
                        </v-list-item>
                        <v-divider :key="x"></v-divider>
                      </template>
                    </template>
                    <template v-else>
                      <v-alert dense border="left" type="warning">
                        No existen horarios confugrados para este día
                      </v-alert>
                    </template>
                  </v-list-item-group>
                </v-list>
                <v-textarea
                  v-model="eventoReagendado.motivo"
                  dense
                  :rules="required"
                  outlined
                  label="Motivo del reagendamiento"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-center">
            <v-btn
              @click="reagendarEvento"
              class="text-center mr-2"
              color="success"
            >
              <v-icon>mdi-floppy</v-icon> REAGENDAR
            </v-btn>
            <v-btn @click="closeDialgoReagendarEvento" class="text-center">
              <v-icon>mdi-cancel</v-icon> CERRAR
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      width="950px"
      v-model="dialogComentarioEvento"
      :persistent="true"
      no-click-animation
    >
      <v-toolbar style="position: static" class="blue-grey lighten-5">
        <v-app-bar-nav-icon>
          <template v-slot:default>
            <v-icon color="primary"
              >mdi-48px mdi-comment-processing-outline</v-icon
            >
          </template>
        </v-app-bar-nav-icon>
        <v-card-title class="justify-center py-0">
          COMETARIOS DEL EVENTO
        </v-card-title>
      </v-toolbar>
      <v-card>
        <v-card-text class="pt-5">
          <p><b>Evento:</b> {{ editComentarioEvento.work_effort_id }}</p>
          <p><b>Servicio:</b> {{ editComentarioEvento.servicio }}</p>
          <p><b>Estado actual:</b> {{ editComentarioEvento.state }}</p>
          <p><b>Cliente:</b> {{ editComentarioEvento.cliente }}</p>
          <p><b>Quien atiende:</b> {{ editComentarioEvento.empleado }}</p>
          <!-- <p><b>Hora de atención:</b> {{ editComentarioEvento.estimated_start_date.split(':')[0]+':'+editComentarioEvento.estimated_start_date.split(':')[1] }} - {{ editComentarioEvento.estimated_completion_date.split(':')[0]+':'+editComentarioEvento.estimated_completion_date.split(':')[1] }}</p> -->
          <p>
            <b>Comentario anterior:</b> {{ editComentarioEvento.otro_motivo }}
          </p>
        </v-card-text>
        <v-card-text>
          <v-form ref="form_cometario_evento">
            <v-row>
              <v-col md="12">
                <v-textarea
                  v-model="editComentarioEvento.motivo"
                  outlined
                  label="Comentario"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-center">
            <v-btn
              @click="guardarComentarioEvento"
              class="text-center mr-2"
              color="success"
            >
              <v-icon>mdi-floppy</v-icon> GUARDAR
            </v-btn>
            <v-btn @click="dialogComentarioEvento = false" class="text-center">
              <v-icon>mdi-cancel</v-icon> CERRAR
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";
import Vue from "vue";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import AgendamientoComponent from "./AgendamientoComponent.vue";

export default {
  name: "CalendarioComponent",
  components: {
    VueTimepicker,
    AgendamientoComponent,
  },
  data: () => ({
    headers: [
      { text: "ID", value: "work_effort_id" },
      { text: "Servicio", value: "servicio" },
      { text: "Cliente", value: "cliente" },
      { text: "Oportunidad", value: "sales_opportunity_id" },
      { text: "Atendido por", value: "empleado" },
      { text: "Fecha", value: "date" },
      { text: "Inicio", value: "start" },
      { text: "Fin", value: "end" },
      { text: "Estado", value: "state" },
      {
        text: "Acciones",
        value: "actions",
        sortable: false,
        width: "180px",
        align: "center",
      },
    ],
    required: [(v) => !!v || "El campo es requerido"],
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    names: [
      "Meeting",
      "Holiday",
      "PTO",
      "Travel",
      "Event",
      "Birthday",
      "Conference",
      "Party",
    ],
    dates: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
    typeToLabel: {
      month: "Mes",
      week: "Semana",
      day: "Día",
      "4day": "4 días",
    },
    itemsPerPage: 30,
    moment: moment(),
    moment2: moment,
    fechaCalendario: moment().format("YYYY-MM-DD"),
    overlay: false,
    dialogAgendamiento: false,
    dialogCalendarioEvento: false,
    dialogFormEvento: false,
    dialogOpcionesAgendaCalendario: false,
    dialogEstadoEvento: false,
    dialogReagendaEvento: false,
    dialogComentarioEvento: false,
    openDivEvent: false,
    horaInicioCalendario: "08:00",
    horaFinCalendario: "20:30",
    intervalCountCalendar: 26,
    originalHoraInicioCalendario: "08:00",
    originalHoraFinCalendario: "20:30",
    horaOpcionAgendaCalendario: "00:00",
    horaOpcionReagendadoCalendario: "00:00",
    nombreOpcionAgendaCalendario: "",
    originalIntervalCountCalendar: 26,
    eventHeight: 45,
    duracion: 0,
    focus: moment().format("YYYY-MM-DD"),
    type: "day",
    state: "CAL_TAR_PLANIFICADO",
    editComentarioEvento: {},
    person: {},
    eventoEstado: {
      estimated_start_date: "00:00",
      estimated_completion_date: "00:00",
    },
    fechaAgendar: null,
    horaAgendar: null,
    servicioIdEventoReagendado: null,
    search: null,
    servicioCrmId: null,
    dataTable: [],
    servicioCrm: [],
    productStores: [],
    servicios: [],
    empleados: [],
    states: [],
    purposes: [],
    events: [],
    subServicios: [],
    subServiciosSeleccionados: [],
    opcionesAgendaCalendario: [],
    opcionesReagendadoCaledario: [],
    clientes: [],
    diasHorarios: [],
    selectedEvent: {},
    eventoReagendado: {
      work_effort_id: null,
      fecha: null,
      servicio_crm_id: null,
      party_id_cliente: null,
      sub_servicio_crm_id: [],
      hora: "00:00",
      motivo: "",
    },
    fechaEvento: null,
    diaSeleccionado: null,
    productStoreId: "",
    productStoreIdEvento: "",
    eventType: null,
    selectedElement: null,
    partyIdCliente: null,
    newStatusId: null,
    motivo: null,
    searchClienteList: "",
    partyId: null,
    selectedOpen: false,
    eventosPlanificados: [
      "CAL_TAR_COMPLETADO",
      "CAL_TAR_CANCELADO",
      "CAL_TAR_NO_REALIZADO",
      "CAL_TAR_CONFIRMADO",
      "CAL_TAR_NOASISTE",
      "CAL_ASIS_NOFA",
    ],
    eventosConfirmados: [
      "CAL_TAR_COMPLETADO",
      "CAL_TAR_NO_REALIZADO",
      "CAL_TAR_CANCELADO",
      "CAL_TAR_PLANIFICADO",
      "CAL_TAR_NOASISTE",
      "CAL_ASIS_NOFA",
    ],
    enventosNoRealizados: [
      "CAL_TAR_PLANIFICADO",
      "CAL_TAR_CANCELADO",
      "CAL_TAR_COMPLETADO",
      "CAL_TAR_NOASISTE",
      "CAL_ASIS_NOFA",
    ],

    enventosNoAsiste: [
      "CAL_TAR_PLANIFICADO",
      "CAL_TAR_CANCELADO",
      "CAL_ASIS_NOFA",
    ],
    eventosAsisteNofa: [
      "CAL_TAR_PLANIFICADO",
      "CAL_TAR_CANCELADO",
      "CAL_TAR_NOASISTE",
    ],

    sales_opp_id: "",
    busEmpleadoId: "",
    motivos: [],
    nuevoMotivoId: "",
  }),
  computed: {
    ...mapState("master", [
      "loadingTable",
      "tenantId",
      "paramAlertQuestion",
      "titleToolbar",
    ]),

    dateRangeText() {
      return this.dates.join(" ~ ");
    },

    productStoreSelected() {
      return this.productStores.find(
        (e) => e.product_store_id == this.productStoreIdEvento
      );
    },

    servicioSelected() {
      return this.servicios.find(
        (e) => e.servicio_crm_id == this.servicioCrmId
      );
    },

    servicioReagendadoSelected() {
      return this.servicios.find(
        (e) => e.servicio_crm_id == this.eventoReagendado.servicio_crm_id
      );
    },

    empleadoSelected() {
      return this.empleados.find((e) => e.party_id == this.partyId);
    },

    descripcionAgendamiento() {
      return `${
        this.diaSeleccionado != null
          ? "El " + moment(this.diaSeleccionado.date).format("LL") + " - a las "
          : ""
      } ${
        this.diaSeleccionado != null
          ? this.diaSeleccionado.hour + " horas - con "
          : ""
      } ${this.searchClienteList}`;
    },

    descripcionFechaseleccionada() {
      return this.focus != "" ? moment(this.focus).format("LLLL") : "";
    },

    duracionEventoReagendado() {
      return (
        (typeof this.servicioReagendadoSelected != "undefined"
          ? this.servicioReagendadoSelected.sub_servicios
              .filter((e) =>
                this.eventoReagendado.sub_servicio_crm_id.includes(
                  e.sub_servicio_crm_id
                )
              )
              .reduce((a, b) => a + parseFloat(b.duracion), 0)
          : 0) +
        (typeof this.servicioReagendadoSelected != "undefined"
          ? parseFloat(this.servicioReagendadoSelected.tiempo_antes)
          : 0) +
        (typeof this.servicioReagendadoSelected != "undefined"
          ? parseFloat(this.servicioReagendadoSelected.tiempo_despues)
          : 0)
      );
    },
  },
  watch: {
    type: function(val) {
      this.openDivEvent = false;
    },

    fechaCalendario: function() {
      this.openDivEvent = false;
    },

    focus: function() {
      this.openDivEvent = false;
    },

    subServiciosSeleccionados: function() {
      this.setSubServiciosSeleccionados();
    },
  },
  methods: {
    ...mapMutations("master", ["setUrl", "setLoadingTable", "setTitleToolbar"]),

    ...mapActions("master", ["requestApi", "alertNotification"]),

    searchEventos() {
      if (this.dateRangeText.split("~").length === 2)
        this.getEventosCalendario("ListadoEventos");
    },

    getWorkeffortTypes() {
      this.setUrl("evento");
      this.overlay = true;

      this.requestApi({
        method: "GET",
        data: {
          tenantId: this.tenantId,
          typeList: "datosVistaEventos",
        },
      })
        .then((res) => {
          this.servicioCrm = res.data._embedded.evento[0];
          this.productStores = res.data._embedded.evento[1];
          this.states = res.data._embedded.evento[2];
          this.servicios = res.data._embedded.evento[0];
          this.productStoreIdEvento =
            typeof res.data._embedded.evento[3] != "undefined"
              ? res.data._embedded.evento[3].default_product_store_id
              : null;
          this.productStoreId =
            typeof res.data._embedded.evento[3] != "undefined"
              ? res.data._embedded.evento[3].default_product_store_id
              : null;
          this.empleados = res.data._embedded.evento[4];
          this.motivos = res.data._embedded.evento[5];
          this.overlay = false;
          this.getEventosCalendario("ListadoEventos");
        })
        .catch(() => {
          this.overlay = false;
        });
    },

    getEventosCalendario(section) {
      let date = "~";
      let psId = "";

      if (section == "CalendarioEventos") {
        date =
          typeof this.$refs.calendar != "undefined"
            ? this.$refs.calendar.lastStart.date +
              "~" +
              this.$refs.calendar.lastEnd.date
            : this.eventoReagendado.fecha + "~" + this.eventoReagendado.fecha;

        psId = this.productStoreIdEvento;
      } else {
        date = this.dateRangeText;
        psId = this.productStoreId;
      }

      if (psId != "") {
        console.log(date);
        console.log(psId);
        console.log(section);
        this.setUrl("evento");
        this.overlay = true;
        this.events = [];
        //console.log("this.partyIdCliente", this.partyIdCliente);
        this.requestApi({
          method: "GET",
          data: {
            tenantId: this.tenantId,
            workEffortTypeId: this.workEffortTypeId,
            productStoreId: psId,
            dates: date,
            state: this.state,
            servicioCrmId: this.eventType,
            typeList: section,
            partyId: this.partyId,
            clientId:
              typeof this.partyIdCliente == "object" &&
              this.partyIdCliente != null
                ? this.partyIdCliente.party_id
                : null,
            viewCalendar: this.type,
            empleadoId: this.busEmpleadoId,
          },
        })
          .then((res) => {
            console.log("res", res);

            if (section == "CalendarioEventos") {
              try {
                this.empleados = res.data._embedded.evento[1];

                this.diasHorarios = res.data._embedded.evento[2];

                this.horaInicioCalendario = this.diasHorarios[
                  date.split("~")[0]
                ][0];

                this.horaFinCalendario = this.diasHorarios[date.split("~")[0]][
                  this.diasHorarios[date.split("~")[0]].length - 1
                ];

                let excepcion = res.data._embedded.evento[3].excepciones.filter(
                  (e) => e.fecha_excepcion == this.focus
                );

                if (excepcion.length) {
                  this.horaInicioCalendario = excepcion[0].desde;
                  this.horaFinCalendario = excepcion[0].hasta;
                }

                if (date.split("~")[0] == date.split("~")[1]) {
                  let desde = moment(this.horaInicioCalendario, "HH:mm:ss");
                  let hasta = moment(this.horaFinCalendario, "HH:mm:ss");

                  this.intervalCountCalendar =
                    moment.duration(hasta.diff(desde)).asMinutes() / 15 + 1;
                } else {
                  let horaMenor = "23:59:59";
                  let horaMayor = "00:00:00";

                  Object.values(this.diasHorarios)
                    .filter((e) => e.length)
                    .forEach((e) => {
                      if (e[0] < horaMenor) horaMenor = e[0];

                      if (e[e.length - 1] > horaMayor)
                        horaMayor = e[e.length - 1];
                    });

                  this.horaInicioCalendario = horaMenor;
                  this.originalHoraFinCalendario = horaMayor;

                  let desde = moment(horaMenor, "HH:mm:ss");
                  let hasta = moment(horaMayor, "HH:mm:ss");

                  this.intervalCountCalendar =
                    moment.duration(hasta.diff(desde)).asMinutes() / 15 + 1;
                }

                res.data._embedded.evento[0].forEach((e) => {
                  let tiempoInicial =
                    e.estimated_start_date.split(":")[0] +
                    ":" +
                    e.estimated_start_date.split(":")[1];
                  let tiempofinal =
                    e.estimated_completion_date.split(":")[0] +
                    ":" +
                    e.estimated_completion_date.split(":")[1];

                  this.events.push({
                    name: `${e.servicio} ${e.cliente}`,
                    cliente: e.cliente,
                    servicio: e.servicio,
                    empleado: e.empleado,
                    start: tiempoInicial,
                    end: tiempofinal,
                    workEffortId: e.work_effort_id,
                    color: e.color, //this.colors[this.rnd(0, this.colors.length - 1)],
                    comentarios: e.comments,
                    showActions: false,
                    timed: true,
                    ...e,
                  });
                });
              } catch (error) {
                console.log(err);
              }
            } else {
              this.dataTable = res.data._embedded.evento;
            }

            this.overlay = false;
          })
          .catch(() => {
            this.overlay = false;
          });
      }
    },

    close() {
      this.dialogCalendarioEvento = false;
    },

    closeFromEvento(site) {
      this.selectedOpen = false;
      this.dialogFormEvento = false;

      if (site == "listado") this.getEventosCalendario("ListadoEventos");
    },

    setProductStoreIdEvento(ps) {
      this.productStoreIdEvento = ps.product_store_id;
      this.getEventosCalendario("CalendarioEventos");
    },

    setServicioCrmIdEvento({ servicio_crm_id }) {
      this.subServiciosSeleccionados = [];
      this.servicioCrmId = servicio_crm_id;
      this.getEventosCalendario("CalendarioEventos");
    },

    setEmpleadoIdEvento(emp) {
      this.partyId = emp.party_id;
      this.getEventosCalendario("CalendarioEventos");
    },

    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },

    agendarCalendario(day, vista = "Calendario") {
      if (day.date >= moment().format("YYYY-MM-DD")) {
        this.diaSeleccionado = day;

        if (
          typeof this.productStoreSelected == "undefined" ||
          !this.productStoreSelected.product_store_id
        ) {
          this.alertNotification({
            param: {
              html: "Debe seleccionar la tienda",
              timer: 10000,
              title: "Error",
              icon: "error",
              confirmButtonColor: "red",
            },
          });

          return false;
        }

        if (
          typeof this.servicioSelected == "undefined" ||
          !this.servicioSelected.servicio_crm_id
        ) {
          this.alertNotification({
            param: {
              html: "Debe seleccionar el servicio",
              timer: 10000,
              title: "Error",
              icon: "error",
              confirmButtonColor: "red",
            },
          });

          return false;
        }

        if (this.partyId == null) {
          this.alertNotification({
            param: {
              html: "Debe seleccionar el empleado",
              timer: 10000,
              title: "Error",
              icon: "error",
              confirmButtonColor: "red",
            },
          });

          return false;
        }

        if (!this.subServiciosSeleccionados.length) {
          this.alertNotification({
            param: {
              html: "Debe seleccionar al menos un sub servicio",
              timer: 10000,
              title: "Error",
              icon: "error",
              confirmButtonColor: "red",
            },
          });

          return false;
        }

        if (this.partyIdCliente == null) {
          this.alertNotification({
            param: {
              html: "Debe seleccionar el cliente",
              timer: 10000,
              title: "Error",
              icon: "error",
              confirmButtonColor: "red",
            },
          });

          return false;
        }

        let hora = day.time.split(":");

        let originalHora = hora[0];
        let originalMin = hora[1];

        let minTime = Math.floor(originalMin / 15) * 15;

        if (minTime == 0) minTime = minTime + "0";

        if (vista == "Calendario")
          this.horaOpcionAgendaCalendario = originalHora + ":" + minTime;

        this.overlay = true;

        this.setUrl("evento");

        this.requestApi({
          method: "GET",
          data: {
            tenantId: this.tenantId,
            servicioCrmId: this.servicioSelected.servicio_crm_id,
            productStoreId: this.productStoreSelected.product_store_id,
            fecha: day.date,
            partyId: this.partyId,
            duracion: this.duracion,
            typeList: "horasPermitidas",
          },
        })
          .then((res) => {
            console.log("res", res);
            this.overlay = false;

            try {
              let habilitado = res.data._embedded.evento[0].empleados[0].disponibilidades[
                this.focus
              ].find((e) => {
                return (
                  moment(
                    this.focus + " " + this.horaOpcionAgendaCalendario
                  ).isBetween(
                    this.focus + " " + e.desde,
                    this.focus + " " + e.hasta,
                    undefined,
                    "[]"
                  ) &&
                  this.returnDiffMinutos({
                    desde: this.horaOpcionAgendaCalendario,
                    hasta: e.hasta,
                  }) +
                    1 >=
                    this.duracion
                );
              });

              console.log("habilitado", habilitado);

              if (typeof habilitado == "undefined") {
                this.alertNotification({
                  param: {
                    html: `No es posible agendar a las ${this.horaOpcionAgendaCalendario}`,
                    timer: 10000,
                    title: "Error",
                    icon: "error",
                    confirmButtonColor: "red",
                  },
                });

                return false;
              } else {
                Vue.swal({
                  html: `Esta seguro agendar el evento? <br /> Se agendará con las siguientes opciones:<br /> ${this.descripcionAgendamiento}`,
                  icon: "warning",
                  input: "textarea",
                  inputPlaceholder: "Escriba un comentario",
                  showCancelButton: true,
                  confirmButtonText: "Aceptar",
                  cancelButtonText: "Cerrar",
                  ...this.paramAlertQuestion,
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.setUrl("evento");
                    this.overlay = true;

                    this.requestApi({
                      method: "POST",
                      data: {
                        tenantId: this.tenantId,
                        typeStore: "guardarEventoCrm",
                        servicioCrmId: this.servicioSelected.servicio_crm_id,
                        productStoreId: this.productStoreSelected.product_store_id,
                        clientePartyId: this.partyIdCliente.party_id,
                        fecha: day.date + " " + this.horaOpcionAgendaCalendario,
                        duracion: this.duracion,
                        employePartyId: this.partyId,
                        subServicios: this.subServiciosSeleccionados,
                        comments: result.value,
                        sales_opp_id: this.sales_opp_id,
                        //purposeId: '',
                      },
                    })
                      .then((res) => {
                        this.alertNotification({
                          param: {
                            html: res.data.res.msg,
                          },
                        });

                        this.getEventosCalendario("CalendarioEventos");
                        this.subServiciosSeleccionados = [];
                        this.dialogOpcionesAgendaCalendario = false;
                        this.horaOpcionAgendaCalendario = "00:00";
                        this.partyIdCliente = null;
                        this.sales_opp_id = "";
                        this.overlay = false;
                      })
                      .catch(() => {
                        this.overlay = false;
                      });
                  }
                });
              }
            } catch (error) {
              console.log("error", error);
            }
          })
          .catch(() => {
            this.overlay = false;
          });
      }
    },

    setSubServiciosSeleccionados() {
      const { tiempo_antes, tiempo_despues } = this.servicioSelected;

      this.duracion = this.servicioSelected.sub_servicios
        .filter((e) =>
          this.subServiciosSeleccionados.includes(e.sub_servicio_crm_id)
        )
        .reduce((a, b) => a + parseFloat(b.duracion), 0);
      this.duracion += parseFloat(tiempo_antes) + parseFloat(tiempo_despues);
    },

    setClienteOp() {
      console.log(this.partyIdCliente.sales_opportunity_id);
      for (let i = 0; i < this.clientes.length; i++) {
        if (
          this.clientes[i].sales_opportunity_id ==
          this.partyIdCliente.sales_opportunity_id
        ) {
          this.sales_opp_id = this.clientes[i].sales_opportunity_id;
        }
      }
    },

    getClientes() {
      return new Promise((resolved) => {
        this.setUrl("evento");
        this.overlay = true;

        this.requestApi({
          method: "GET",
          data: {
            tenantId: this.tenantId,
            typeList: "clientesFormEvento",
            cliente: this.searchClienteList,
          },
        })
          .then((res) => {
            this.clientes = res.data._embedded.evento;

            this.overlay = false;

            resolved();
          })
          .catch(() => {
            this.overlay = false;
          });
      });
    },

    getOpcionesAgendaCalendario() {
      if (
        typeof this.productStoreSelected == "undefined" ||
        !this.productStoreSelected.product_store_id
      ) {
        this.alertNotification({
          param: {
            html: "Debe seleccionar la tienda",
            timer: 10000,
            title: "Error",
            icon: "error",
            confirmButtonColor: "red",
          },
        });

        return false;
      }

      if (
        typeof this.servicioSelected == "undefined" ||
        !this.servicioSelected.servicio_crm_id
      ) {
        this.alertNotification({
          param: {
            html: "Debe seleccionar el servicio",
            timer: 10000,
            title: "Error",
            icon: "error",
            confirmButtonColor: "red",
          },
        });

        return false;
      }

      if (!this.subServiciosSeleccionados.length) {
        this.alertNotification({
          param: {
            html: "Debe seleccionar al menos un sub servicio",
            timer: 10000,
            title: "Error",
            icon: "error",
            confirmButtonColor: "red",
          },
        });

        return false;
      }

      this.overlay = true;

      this.setUrl("evento");

      this.requestApi({
        method: "GET",
        data: {
          tenantId: this.tenantId,
          servicioCrmId: this.servicioSelected.servicio_crm_id,
          productStoreId: this.productStoreSelected.product_store_id,
          fecha: this.focus,
          duracion: this.duracion,
          typeList: "horasPermitidas",
        },
      }).then((res) => {
        this.opcionesAgendaCalendario = res.data._embedded.evento[0].empleados;
        this.overlay = false;
      });
      this.dialogOpcionesAgendaCalendario = true;
    },

    closeDialogOpcionesAgendaCalendario() {
      this.dialogOpcionesAgendaCalendario = false;
      this.opcionesAgendaCalendario = [];
      this.horaOpcionAgendaCalendario = "00:00";
      this.nombreOpcionAgendaCalendario = "";
      this.partyIdCliente = null;
      this.setEmpleadoIdEvento({ party_id: null });
    },

    returnDiffMinutos({ desde, hasta }) {
      console.log(desde, hasta);
      return moment(moment().format("YYYY-MM-DD " + hasta)).diff(
        moment().format("YYYY-MM-DD " + desde),
        "minutes"
      );
    },

    getEventColor(event) {
      return event.color;
    },

    rangoMinutos({ desde, hasta }, reagendar = false) {
      let hora = reagendar
        ? this.horaOpcionReagendadoCalendario
        : this.horaOpcionAgendaCalendario;

      console.log(
        "hasta",
        hasta,
        "desde",
        desde,
        "hora",
        hora,
        "duracion",
        this.duracion
      );
      if (hora.split(":")[0] == desde.split(":")[0]) {
        //DESDE
        if (desde.split(":")[0] == hasta.split(":")[0]) {
          //DESDE Y HASTA TIENE EL MISMO INTERVALO DE HORA

          //NO ALCANZAN LOS MINUTOS
          if (this.duracion > parseInt(hasta.split(":")[1])) {
            return [];
          } else {
            return [
              desde.split(":")[1],
              hasta.split(":")[1] - this.duracion < desde.split(":")[1]
                ? 59
                : hasta.split(":")[1] - this.duracion,
            ];
          }
        } else {
          if (this.duracion > 59) {
            return [desde.split(":")[1], 59];
          } else {
            return [
              desde.split(":")[1],
              59 - this.duracion < desde.split(":")[1]
                ? 59
                : 59 - this.duracion,
            ];
          }
        }
      } else {
        //TODOS LOS MINUTOS
        if (
          hora.split(":")[0] == hasta.split(":")[0] &&
          hasta.split(":")[1] == "00"
        ) {
          return [];
        } else {
          if (
            hora.split(":")[0] > desde.split(":")[0] &&
            hora.split(":")[0] < hasta.split(":")[0]
          ) {
            return [0, 59];
          } else if (hora.split(":")[0] == hasta.split(":")[0]) {
            //NO ALCANZAN LOS MINUTOS
            if (this.duracion > parseInt(hasta.split(":")[1])) {
              return [];
            } else {
              if (this.duracion > 59) {
                return [hasta.split(":")[1], 59];
              } else {
                return [
                  hasta.split(":")[1],
                  59 - this.duracion < hasta.split(":")[1]
                    ? 59
                    : 59 - this.duracion,
                ];
              }
            }
          }
        }
      }
    },

    setPersonaYhora({ nombre, party_id }) {
      this.horaOpcionAgendaCalendario = "00:00";
      this.nombreOpcionAgendaCalendario = nombre;
      this.setEmpleadoIdEvento({ party_id });
    },

    setToday() {
      this.focus = "";
    },

    changeCalendarDay(day) {
      this.focus = day;
    },

    seleccionaEventoEstado(e, item) {
      e.preventDefault();
      e.stopPropagation();

      this.eventoEstado = item;
      this.dialogEstadoEvento = true;
    },

    cambiarEstadoEvento() {
      if (!this.$refs.form_estatus_evento.validate()) return false;

      Vue.swal({
        html: `Esta seguro de actualizar el estado del evento?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.overlay = true;

          this.setUrl("evento");

          const { work_effort_id } = this.eventoEstado;

          this.requestApi({
            method: "POST",
            data: {
              tenantId: this.tenantId,
              workEffortId: work_effort_id,
              statusId: this.newStatusId,
              motivo: this.motivo,
              motivoId: this.nuevoMotivoId,
              typeStore: "actualizaEstadoEvento",
            },
          })
            .then((res) => {
              this.getEventosCalendario("CalendarioEventos");
              this.getEventosCalendario("ListadoEventos");

              this.alertNotification({
                param: {
                  html: res.data.res.msg,
                },
              });

              this.eventoEstado = {
                estimated_start_date: "00:00",
                estimated_completion_date: "00:00",
              };
              this.dialogEstadoEvento = false;
              this.motivo = null;
              this.overlay = false;
            })
            .catch((err) => {
              this.overlay = false;
              console.log(err);
            });
        }
      });

      //eventoEstado
    },

    setDataReagendarEvento(event) {

      this.eventoReagendado.servicio_crm_id = event.servicio_crm_id;

      this.eventoReagendado.sub_servicio_crm_id = event.sub_servicios.map((e) => { return e.sub_servicio_crm_id; });

      this.eventoReagendado.fecha = event.estimated_start_date.split(" ")[0];

      this.eventoReagendado.hora = `${moment(event.estimated_start_date.split(" ")[1],"HH:mm:ss").format("HH:mm")} - ${moment(event.estimated_completion_date.split(" ")[1],"HH:mm:ss").format("HH:mm")}`;

      this.horaOpcionReagendadoCalendario = moment(event.estimated_start_date.split(" ")[1],"HH:mm:ss").format("HH:mm");

      this.nombreOpcionAgendaCalendario = event.empleado;

      this.eventoReagendado.party_id_cliente = event.party_id_cliente;

      this.partyId = event.party_id_empleado;

      this.eventoReagendado.work_effort_id = event.work_effort_id;

      this.searchClienteList = event.cliente;

      this.searchDisponibilidadReagendado();
      console.log("event", event);
      
    },

    searchDisponibilidadReagendado() {
      this.overlay = true;
      this.opcionesReagendadoCaledario = [];
      console.log(
        "this.duracionEventoReagendado",
        this.duracionEventoReagendado
      );
      this.requestApi({
        method: "GET",
        data: {
          tenantId: this.tenantId,
          servicioCrmId: this.eventoReagendado.servicio_crm_id,
          productStoreId: this.productStoreSelected.product_store_id,
          fecha: this.eventoReagendado.fecha,
          duracion: this.duracionEventoReagendado,
          typeList: "horasPermitidas",
        },
      }).then((res) => {
        this.opcionesReagendadoCaledario =
          res.data._embedded.evento[0].empleados;
        this.dialogReagendaEvento = true;

        this.overlay = false;
      });
    },
    nl2br(str, is_xhtml) {
      if (typeof str === "undefined" || str === null) {
        return "";
      }
      var breakTag =
        is_xhtml || typeof is_xhtml === "undefined" ? "<br />" : "<br>";
      return (str + "").replace(
        /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
        "$1" + breakTag + "$2"
      );
    },
    reagendarEvento() {
      if (this.eventoReagendado.fecha >= moment().format("YYYY-MM-DD")) {
        if (
          typeof this.productStoreSelected == "undefined" ||
          !this.productStoreSelected.product_store_id
        ) {
          this.alertNotification({
            param: {
              html: "Debe seleccionar la tienda",
              timer: 10000,
              title: "Error",
              icon: "error",
              confirmButtonColor: "red",
            },
          });

          return false;
        }

        if (!this.eventoReagendado.servicio_crm_id) {
          this.alertNotification({
            param: {
              html: "Debe seleccionar el servicio",
              timer: 10000,
              title: "Error",
              icon: "error",
              confirmButtonColor: "red",
            },
          });

          return false;
        }

        if (this.partyId == null) {
          this.alertNotification({
            param: {
              html: "Debe seleccionar el empleado",
              timer: 10000,
              title: "Error",
              icon: "error",
              confirmButtonColor: "red",
            },
          });

          return false;
        }

        if (!this.eventoReagendado.sub_servicio_crm_id.length) {
          this.alertNotification({
            param: {
              html: "Debe seleccionar al menos un sub servicio",
              timer: 10000,
              title: "Error",
              icon: "error",
              confirmButtonColor: "red",
            },
          });

          return false;
        }

        if (this.eventoReagendado.party_id_cliente == null) {
          this.alertNotification({
            param: {
              html: "Debe seleccionar el cliente",
              timer: 10000,
              title: "Error",
              icon: "error",
              confirmButtonColor: "red",
            },
          });

          return false;
        }

        this.overlay = true;

        this.setUrl("evento");

        this.requestApi({
          method: "GET",
          data: {
            tenantId: this.tenantId,
            servicioCrmId: this.eventoReagendado.servicio_crm_id,
            productStoreId: this.productStoreSelected.product_store_id,
            fecha: this.eventoReagendado.fecha,
            partyId: this.partyId,
            duracion: this.duracionEventoReagendado,
            excludeWorkEffortId: this.eventoReagendado.work_effort_id,
            typeList: "horasPermitidas",
          },
        })
          .then((res) => {
            console.log("res", res);
            this.overlay = false;

            try {
              let hora = this.horaOpcionReagendadoCalendario
                .split("-")[0]
                .trim();

              this.diaSeleccionado = {
                date: this.eventoReagendado.fecha,
                hour: hora,
              };

              let habilitado = res.data._embedded.evento[0].empleados[0].disponibilidades[
                this.eventoReagendado.fecha
              ].find((e) => {
                return (
                  moment(this.eventoReagendado.fecha + " " + hora).isBetween(
                    this.eventoReagendado.fecha + " " + e.desde,
                    this.eventoReagendado.fecha + " " + e.hasta,
                    undefined,
                    "[]"
                  ) &&
                  this.returnDiffMinutos({ desde: hora, hasta: e.hasta }) + 1 >=
                    this.duracionEventoReagendado
                );
              });
              console.log("habilitado", habilitado);
              if (typeof habilitado == "undefined") {
                this.alertNotification({
                  param: {
                    html: `No es posible agendar a las ${hora}`,
                    timer: 10000,
                    title: "Error",
                    icon: "error",
                    confirmButtonColor: "red",
                  },
                });

                return false;
              } else {
                Vue.swal({
                  html: `Esta seguro reagendar el evento? <br /> Se reagendará con las siguientes opciones:<br /> ${this.descripcionAgendamiento}`,
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonText: "Aceptar",
                  cancelButtonText: "Cerrar",
                  ...this.paramAlertQuestion,
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.setUrl("evento");
                    this.overlay = true;

                    this.requestApi({
                      method: "POST",
                      data: {
                        tenantId: this.tenantId,
                        typeStore: "reagendarEvento",
                        servicioCrmId: this.eventoReagendado.servicio_crm_id,
                        productStoreId: this.productStoreSelected
                          .product_store_id,
                        clientePartyId: this.eventoReagendado.party_id_cliente,
                        fecha: this.eventoReagendado.fecha + " " + hora,
                        duracion: this.duracionEventoReagendado,
                        employePartyId: this.partyId,
                        subServicios: this.eventoReagendado.sub_servicio_crm_id,
                        workEffortId: this.eventoReagendado.work_effort_id,
                        motivo: this.eventoReagendado.motivo,
                        //purposeId: '',
                      },
                    })
                      .then((res) => {
                        this.getEventosCalendario("CalendarioEventos");
                        this.getEventosCalendario("ListadoEventos");
                        this.closeDialgoReagendarEvento();

                        this.alertNotification({
                          param: {
                            html: res.data.res.msg,
                          },
                        });

                        this.overlay = false;
                      })
                      .catch(() => {
                        this.overlay = false;
                      });
                  }
                });
              }
            } catch (error) {
              console.log("error", error);
            }
          })
          .catch(() => {
            this.overlay = false;
          });
      }
    },

    closeDialgoReagendarEvento() {
      this.dialogReagendaEvento = false;
      this.opcionesReagendadoCaledario = [];
      this.horaOpcionReagendadoCalendario = "00:00";
      this.nombreOpcionAgendaCalendario = "";
      this.eventoReagendado = {
        work_effort_id: null,
        fecha: null,
        servicio_crm_id: null,
        party_id_cliente: null,
        sub_servicio_crm_id: [],
        hora: "00:00",
        motivo: "",
      };
    },

    verEstadoEvento(event) {
      this.editComentarioEvento = event;
      this.editComentarioEvento.motivo = event.otro_motivo;
      this.dialogComentarioEvento = true;
    },

    guardarComentarioEvento() {
      Vue.swal({
        html: "Esta seguro editar el comentario del evento?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("evento");
          this.overlay = true;

          this.requestApi({
            method: "POST",
            data: {
              typeStore: "guardarComentarioEvento",
              workEffortId: this.editComentarioEvento.work_effort_id,
              motivo: this.editComentarioEvento.motivo,
            },
          })
            .then((res) => {
              this.getEventosCalendario("ListadoEventos");
              this.dialogComentarioEvento = false;

              this.alertNotification({
                param: {
                  html: res.data.res.msg,
                },
              });

              this.overlay = false;
            })
            .catch(() => {
              this.overlay = false;
            });
        }
      });
    },

    enterDay({ date }) {
      this.focus = date;
      this.fechaCalendario = date;
      this.type = "day";
    },

    showEvent(event, nativeEvent) {
      let ele = nativeEvent.target.parentNode.parentNode;

      if (this.type != "month") {
        if (this.openDivEvent) {
          ele.style.height = ele.dataset.originalHeight;
          ele.style.width = ele.dataset.originalWidth;
          ele.style.removeProperty("z-index");
          ele.style.removeProperty("overflow");

          this.events.map((e) => {
            e.showActions = false;
            return e;
          });
        } else {
          ele.style.zIndex = "9999";
          ele.style.overflow = "auto";

          if (typeof ele.dataset.originalHeight == "undefined") {
            ele.dataset.originalHeight = ele.style.height;
            ele.dataset.originalWidth = ele.style.width;
          }

          let desde = moment(event.start);
          let hasta = moment(event.end);

          let diff = moment.duration(hasta.diff(desde)).asMinutes();

          if (diff <= 50) {
            ele.style.height = parseFloat(ele.style.height) + 120 + "px";
          } else {
            ele.style.height = parseFloat(ele.style.height) + 50 + "px";
          }

          ele.style.transition = "all ease .5s";

          if (this.type != "day") ele.style.width = "420px";

          this.events.map((e) => {
            e.showActions = event.workEffortId != e.workEffortId;

            return e;
          });
        }
      }

      //console.log('event',event,'nativeEvent',nativeEvent)

      this.openDivEvent = !this.openDivEvent;
      nativeEvent.stopPropagation();
    },

    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },

    getListaEstadosValidos(estadoId) {
      if (this.eventoEstado.current_status_id == "CAL_TAR_PLANIFICADO") {
        return this.eventosPlanificados;
      } else if (this.eventoEstado.current_status_id == "CAL_TAR_CONFIRMADO") {
        return this.eventosConfirmados;
      } else if (this.eventoEstado.current_status_id == "CAL_TAR_NOASISTE") {
        return this.enventosNoAsiste;
      } else if (this.eventoEstado.current_status_id == "CAL_ASIS_NOFA") {
        return this.eventosAsisteNofa;
      } else {
        return this.enventosNoRealizados;
      }
    },

    getListaMotivosEstado(estadoId) {
      let me = [];
      for (let i = 0; i < this.motivos.length; i++) {
        if (this.motivos[i].work_effort_status_id_id == estadoId) {
          me.push(this.motivos[i]);
        }
      }
      return me;
    },
  },
  created() {
    this.getWorkeffortTypes();
    this.setTitleToolbar("EVENTOS CRM");
  },
  mounted() {
    if (
      typeof this.$route.params.salesOppId != "undefined" &&
      typeof this.$route.params.clienteId != "undefined"
    ) {
      this.sales_opp_id = this.$route.params.salesOppId;
      /*this.clientes = [{ party_id: this.$route.params.clienteId, nombre: "" }];
        this.partyIdCliente = {
          party_id: this.$route.params.clienteId,
          nombre: "",
        };*/
      this.searchClienteList = this.$route.params.clienteId;
      this.getClientes();
    }
  },
};
</script>

<style>
.label-day-view {
  padding: 10px;
  color: white;
  background-color: green;
  margin: 0px auto;
  border-radius: 50px;
  width: 45px;
}

.v-select.v-input--dense .v-select__selection--comma {
  font-size: 11px !important;
}
</style>
